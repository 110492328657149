module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bragazzi's","short_name":"Bragazzi's","description":"Purveyors of quality Italian goods. We are a cafe, shop and delicatessen.","start_url":"/","background_color":"#f6f4f1","theme_color":"#1d1d1d","display":"standalone","icon":"src/images/logo.png","icon_options":{"purpose":"any maskable"},"lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a45db8457a139a9a81b08dd0e2702aa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
